import { AxiosResponse } from 'axios';
import userAuthorizedAxios from './userAuthorizedAxios';
import {
  QuestionnaireFlowResponse,
  PhysioMarkers,
  PhysioMarkersResponse,
  DietConstraint,
  DietsResponse,
  Diet,
  FoodsResponse,
  TakeoutFrequency,
  LifeStyle,
  DesiredFocusGoal,
  DesiredFocusValue,
  WomensHealth, Medication, MedicationReason, MedicationData,
} from '../../interfaces/Onboarding/Questionnaire';
import {
  OnboardingStatusResponse,
  OnboardingFlowResponse,
  DemographicsRequest,
  DemographicsResponse,
  CustomerCoach,
  Mbd,
  HearAboutAnswers,
} from '../../interfaces/Onboarding/Onboarding';
import { PostPurchaseResponse } from '../../interfaces/Customer/ShopifyGateway';
import { FoodFrequencyValue } from '../../interfaces/Profile/Nutrition/FoodFrequencyValue';
import { Food } from '../../interfaces/Profile/Nutrition/Food';

export default {
  getQuestFlow: (): Promise<QuestionnaireFlowResponse> => userAuthorizedAxios
    .get('/api/pages/questionnaire/flow')
    .then((response) => response.data),

  getOnboardingStatus: (): Promise<OnboardingStatusResponse> => userAuthorizedAxios
    .get('/api/pages/onboarding/status')
    .then((response) => response.data),

  getOnboardingFlow: (): Promise<OnboardingFlowResponse> => userAuthorizedAxios
    .get('/api/pages/onboarding/flow')
    .then((response) => response.data),

  postDemographics: (form: DemographicsRequest): Promise<DemographicsResponse> => userAuthorizedAxios
    .post('/api/customer/onboarding/demographics', form)
    .then((response) => response.data),

  startQuest: () => userAuthorizedAxios
    .post('/api/customer/onboarding/questionnaire/start')
    .then((response) => response.data),

  skipQuest: () => userAuthorizedAxios
    .get('/api/pages/questionnaire/skip')
    .then((response) => response.data),

  getPhysioMarkers: (): Promise<PhysioMarkersResponse> => userAuthorizedAxios
    .get('/api/pages/physioMarkers')
    .then((response) => response.data),

  getLifestyle: (): Promise<LifeStyle> => userAuthorizedAxios
    .get('/api/pages/lifestyle')
    .then((response) => response.data),

  getDesiredFocusGoal: (): Promise<DesiredFocusGoal> => userAuthorizedAxios
    .get('/api/pages/desiredFocus/goal')
    .then((response) => response.data),

  getDesiredFocusValues: (): Promise<DesiredFocusValue[]> => userAuthorizedAxios
    .get('/api/pages/desiredFocus/values')
    .then((response) => response.data),

  postDesiredFocusGoal: (form: DesiredFocusGoal) => userAuthorizedAxios
    .post('/api/pages/desiredFocus/post', form)
    .then((response) => response.data),

  postLifeStyle: (form: {
    sleepDuration: number,
    smokingStatus: boolean,
    sunExposureAmount: string,
  }) => userAuthorizedAxios
    .post('/api/pages/lifestyle/lifestylePost', form)
    .then((response) => response.data),

  postPhysioMarkers: (form: PhysioMarkers) => userAuthorizedAxios
    .post('/api/pages/physioMarkers/physioMarkersPost', form)
    .then((response) => response.data),

  getCoach: (): Promise<CustomerCoach> => userAuthorizedAxios
    .get('/api/pages/customerCoach')
    .then((response) => response.data),

  postPurchase: (purchaserId: number): Promise<PostPurchaseResponse> => userAuthorizedAxios
    .post('/api/pages/postPurchase/token', { purchaserId })
    .then((response) => response.data),

  postPurchaseOrderId: (orderId: number): Promise<PostPurchaseResponse> => userAuthorizedAxios
    .post('/api/pages/postPurchase/tokenByOrderId', { orderId })
    .then((response) => response.data),

  getDietaryOptions: (): Promise<Diet[]> => userAuthorizedAxios
    .get('/api/pages/dietary/dietaryValues')
    .then(({ data }: AxiosResponse<DietsResponse>) => Object.keys(data)
      .map((name) => ({
        name,
        title: data[name].title,
        tooltip: data[name].tooltip,
      }))),

  getSelectedDietary: (): Promise<string[]> => userAuthorizedAxios
    .get('/api/pages/dietary/dietaryConstraints')
    .then((
      response: AxiosResponse<Record<'constraints', DietConstraint[]>>,
    ) => response.data.constraints.map((diet) => diet.name),
    ),

  postSelectedDietary: (constraints: string[]) => userAuthorizedAxios
    .post('/api/pages/dietary/constraintsPost', {
      constraints,
    })
    .then((response) => response.data),

  getFoods: (): Promise<FoodsResponse> => userAuthorizedAxios
    .get('/api/pages/foods/foodsReference')
    .then((response) => response.data),

  getFoodsValues: (): Promise<FoodFrequencyValue[]> => userAuthorizedAxios
    .get('/api/pages/foods/foodsValues')
    .then((response) => response.data.frequencies),

  postFoods: (frequencies: FoodFrequencyValue[]) => userAuthorizedAxios
    .post('/api/pages/foods/foodsPost', { frequencies })
    .then((response) => response.data),

  getBeverages: (): Promise<Food[]> => userAuthorizedAxios
    .get('/api/pages/foods/beverages')
    .then((response) => response.data.beverages),

  getBeveragesValues: (): Promise<FoodFrequencyValue[]> => userAuthorizedAxios
    .get('/api/pages/foods/beveragesValues')
    .then((response) => response.data.frequencies),

  postBeverages: (frequencies: FoodFrequencyValue[]) => userAuthorizedAxios
    .post('/api/pages/foods/beveragesPost', { frequencies })
    .then((response) => response.data),

  getTakeout: (): Promise<Record<number, TakeoutFrequency>> => userAuthorizedAxios
    .get('/api/pages/takeout/takeoutFrequencies')
    .then((response) => response.data),

  getTakeoutValues: (): Promise<number> => userAuthorizedAxios
    .get('/api/pages/takeout/takeoutValues')
    .then((response) => response.data.takeoutFrequency),

  postTakeout: (takeoutFrequency: number) => userAuthorizedAxios
    .post('/api/pages/takeout/takeoutPost', { takeoutFrequency })
    .then((response) => response.data),

  getWomensHealth: (): Promise<WomensHealth> => userAuthorizedAxios
    .get('/api/customer/profile/woman-health')
    .then((response) => response.data),

  postWomensHealth: (data: WomensHealth) => userAuthorizedAxios
    .patch('/api/customer/profile/woman-health', data)
    .then((response) => response.data),

  postMbd: (data: Mbd) => userAuthorizedAxios
    .post('/api/pages/MobileBloodDraw/confirm', data)
    .then((response) => response.data),

  getHearAboutAnswers: (): Promise<HearAboutAnswers[]> => userAuthorizedAxios
    .get('/api/customer/onboarding/demographics/where-did-you-hear-answers')
    .then((response) => response.data),

  getMedicationReasons: (): Promise<MedicationReason[]> => userAuthorizedAxios
    .get('/api/public/v1/glp-one/medication-reasons')
    .then((response) => response.data.items),

  getMedications: (): Promise<Medication[]> => userAuthorizedAxios
    .get('/api/public/v1/glp-one/medications')
    .then((response) => response.data.items),

  getMedicationData: () => userAuthorizedAxios
    .get('/api/customer/v1/questionnaire/medications-step')
    .then((response) => response.data),

  postMedicationData: (medications: MedicationData[]) => userAuthorizedAxios
    .post('/api/customer/v1/questionnaire/medications-step', {
      medications,
    })
    .then((response) => response.data),
};
