import { IconsDictionaryType } from './types';

const Icons: IconsDictionaryType = {
  general: () => import('./Collections/General'),
  homepage: () => import('./Collections/Homepage'),
  tour: () => import('./Collections/Tour'),
  pages: () => import('./Collections/Pages'),
  science: () => import('./Collections/Science'),
  coach: () => import('./Collections/Coach'),
  innerage: () => import('./Collections/Innerage'),
  onboarding: () => import('./Collections/Onboarding'),
  elements: () => import('./Collections/Elements'),
  plansInfo: () => import('./Collections/PlansInfo'),
  products: () => import('./Collections/Products'),
  dna: () => import('./Collections/Dna'),
  partners: () => import('./Collections/Partners'),
  biomarkers: () => import('./Collections/Biomarkers'),
  profile: () => import('./Collections/Profile'),
};

export default Icons;
